<template>
  <div class="home">
    <div class="pc-container d-none d-sm-block">

      <!-- <div class="banner2 d-flex flex-column justify-center align-center">
        <div class="text-title white--text text-center">{{ $t('Home.bannerTitle') }}</div>
        <div class="text-title2 white--text text-center mt-4">{{ $t('Home.bannerTime') }}</div>
        <v-btn depressed href="https://docs.google.com/forms/d/e/1FAIpQLSfHMw9Hfgf2vbQRc8v0kCkj9x9GaulVR4i2mfOCh3bnbczOdg/viewform?usp=sf_link" target="_blank" height="50" color="#AC8250" class="mt-4"> 
          <div class="white--text text-body-1">{{ $t('Home.bannerBTN') }}</div>
        </v-btn>
      </div> -->

      <div class="banner d-flex flex-column justify-center align-center">
        <img width="100%" height="auto" src="@/assets/images/banner2.jpg" />
        <div class="text-box">
          <div class="text-title white--text text-center">{{ $t('Home.Title') }}</div>
          <div class="text-h6 white--text mt-5 mx-auto" style="width:800px;">{{ $t('Page.Home.Desciption') }}</div>
        </div>
      </div>

      <div class="block-a" style="padding: 130px 120px 150px">
        <div class="text-h4 text-center font-weight-bold" style="color:#8A694C; margin: 50px 0 20px">{{ $t('Page.Home.Title3') }}</div>
        <div class="text-body-1 text-center font-weight-medium">{{ $t('Page.Home.SCTDefinition') }}</div>
        <!-- <div v-if="flipCountdow">
          <div class="text-body-1 text-center font-weight-bold mt-10">{{ $t('Page.Home.Buycon1') }} <span style="color:#8A694C">2023-03-29</span> 19:00:00 JST</div>
          <div class="text-body-1 text-center font-weight-medium mt-2 mb-4">{{ $t('Page.Home.Buycon2') }}</div>
          <flip-countdown deadline="2023-03-29 19:00:00" @timeElapsed="timeElapsedHandler"></flip-countdown>
        </div> -->
        

        <div class="text-body-2 text-center font-weight-medium mt-14 mb-6">{{ $t('Page.Home.Buycon3') }}</div>
        <div class="text-center">
          <v-card elevation="0" class="d-inline-block mx-auto" color="#FCF9F6">
            <div class="d-flex justify-start align-center">
              
              <!-- <v-icon color="#AC8250" size="50" class="mx-4">mdi-arrow-right</v-icon> -->
              <div>
                <v-card elevation="0" color="#F1E8E7" class="d-flex justify-center align-center pa-2">
                    <v-img width="32" height="32" src="@/assets/images/usdt.png" class="rounded-circle"></v-img>
                  <div class="pa-2  ml-4" style="width:120px">
                    <input type="number" class="font-weight-medium" style="width:100px" v-model="usdcAmount" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" readonly="true"  @change="calcSct">
                  </div>
                  <div class="font-weight-regular ml-1 grey--text">USDT</div>
                </v-card>
                <div class="text-body-2 text-center font-weight-medium mt-2">{{ $t('Page.Home.Buycon5') }}</div>
              </div>
              <div class="mx-4 text-h5 font-weight-regular mb-4" style="color:#8A694C">→</div>
              <div>
                <v-card elevation="0" color="#F1E8E7" class="d-flex justify-center align-center pa-2">
                    <v-img width="32" height="32" src="@/assets/images/logo.png" class="rounded-circle"></v-img>
                  <div class="pa-2 ml-4" style="width:120px">
                    <input autofocus focus  type="number" class="font-weight-medium" style="width:100px" v-model="sctAmount" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" readonly="true" @change="calcUsdc">
                  </div>
                  <div class="font-weight-regular ml-1 grey--text">SCT</div>
                </v-card>
                <div class="text-body-2 text-center font-weight-medium mt-2">{{ $t('Page.Home.Buycon4') }}</div>
              </div>
              <v-btn  depressed href="https://dapp.supercells.pro/swap" target="_blank" width="240" class="mb-6 rounded-pill" min-height="50" color="#8A694C" style="margin-left:50px">
                <div class="text-body-1 font-weight-medium white--text">{{ $t('Page.Home.Buycon6') }}</div>
              </v-btn>
            </div>
          </v-card>
        </div>
        <div class="text-h4 text-center font-weight-bold" style="margin: 100px 0 50px">{{ $t('Page.Home.Title2') }}</div>
        <v-btn depressed width="100%" height="260" class="text-left rounded-pill" style="border: 2px solid #77562e;background: linear-gradient(180deg,#ac8250, #9b7240 100%);"> 
          <div class="white--text">
            <!-- <div style="font-size:60px">{{ $t('Page.Home.Plan') }}</div> -->
            <div class="text-h6 font-weight-medium" v-html="$t('Page.Home.PlanD')" :style="[{'line-height':(newsil8n=='en'? '20px':'')},{'white-space':'break-spaces'}]"></div>
          </div>
        </v-btn>
      </div>

      <div class="block-b" style="padding: 50px 120px 80px">
        <div class="d-flex justify-space-between align-center mt-15 mx-auto" style="max-width:1800px">
          <div style="max-width:40%">
            <div class="text-h5 font-weight-bold">{{ $t('Page.Home.SupportDesc1') }}</div>
            <div class="mt-6 text-subtitle-1" v-html="$t('Page.Home.SupportDescription')"></div>
            <v-btn depressed href="https://dapp.supercells.pro/foundation" target="_blank" height="50" color="#AC8250" class="mt-8" style="position:relative;z-index:2"> 
              <div class="white--text text-body-1">{{ $t('Page.Home.CreateFBtn') }}</div>
            </v-btn>
          </div>
          <div style="max-width:40%">
            <img src="@/assets/images/block_b_img.png" />
          </div>
        </div>
      </div>

      <div class="block-c" style="padding: 50px 120px 80px">
        <div class="d-flex justify-space-between align-center mt-15 mx-auto" style="max-width:1800px">
          <div style="max-width:40%">
            <img src="@/assets/images/block_c_img.png" />
          </div>
          <div style="max-width:40%">
            <div class="text-h5 font-weight-bold">{{ $t('Page.Home.PrivTitle') }}</div>
            <div class="mt-6">{{ $t('Page.Home.PrivDescription') }}</div>
          </div>
        </div>
      </div>
      <div style="padding: 80px 120px;background: #FFF8F8">
        <div class="text-h5 font-weight-bold text-center">{{ $t('AppBar.SubMenu.Partner') }}</div>
        <v-row class="mt-10">
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-1.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-2.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-3.png" class="d-block" />
          </v-col>
          <!-- <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-4.png" class="d-block" />
          </v-col> -->
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-5.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-6.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-7.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-8.png" class="d-block" />
          </v-col>
          <v-col class="d-flex justify-center align-center" cols="12"  sm="6" md="3" lg="3">
              <img height="60px" src="@/assets/images/logo-9.png" class="d-block" />
          </v-col>
        </v-row>
        
      </div>
      <Footer></Footer>
    </div>

    <!-- mobile -->
    <div class="mobile-container d-block d-sm-none">

      <!-- <div class="banner2 d-flex flex-column justify-center align-center">
        <div class="text-title white--text text-center">{{ $t('Home.bannerTitle') }}</div>
        <div class="text-title2 white--text text-center mt-4">{{ $t('Home.bannerTime') }}</div>
        <v-btn depressed href="https://docs.google.com/forms/d/e/1FAIpQLSfHMw9Hfgf2vbQRc8v0kCkj9x9GaulVR4i2mfOCh3bnbczOdg/viewform?usp=sf_link" target="_blank" height="50" color="#AC8250" class="mt-4"> 
          <div class="white--text text-body-1">{{ $t('Home.bannerBTN') }}</div>
        </v-btn>
      </div> -->

      <div class="banner text-center white--text px-6 py-15">
        <div class="text-title text-left">Life Sciences Cells Bank Foundation</div>
        <div class="text-body-2 text-left">{{ $t('Page.Home.Desciption') }}</div>
      </div>

      <div class="block-a px-6 pt-15">
        <div class="pb-15">
          <div class="text-h6 text-center font-weight-bold" style="color:#8A694C; margin: 30px 0 20px">{{ $t('Page.Home.Title3') }}</div>
          <div class="font-weight-bold text-left">{{ $t('Page.Home.SCTDefinition') }}</div>
          <!-- <div v-if="flipCountdow">
             <div class="text-body-1 text-center font-weight-bold mt-10">{{ $t('Page.Home.Buycon1') }} <span style="color:#8A694C">2023-03-29</span> 19:00:00 JST</div>
              <div class="text-body-1 text-center font-weight-medium mt-2 mb-4">{{ $t('Page.Home.Buycon2') }}</div>
              <flip-countdown deadline="2023-03-29 19:00:00" @timeElapsed="timeElapsedHandler"></flip-countdown>
          </div> -->
          <div class="text-body-2 text-center font-weight-medium mt-14 mb-6">{{ $t('Page.Home.Buycon3') }}</div>
        <div class="text-center">
          <v-card elevation="0" class="d-inline-block mx-auto" color="#FCF9F6">
            <div class="d-flex flex-column justify-start align-center">
              <!-- <v-icon color="#AC8250" size="50" class="mx-4">mdi-arrow-right</v-icon> -->
              <div>
                <v-card elevation="0" color="#F1E8E7" class="d-flex justify-center align-center pa-2">
                    <v-img width="32" height="32" src="@/assets/images/usdt.png" class="rounded-circle"></v-img>
                  <div class="pa-2  ml-4" style="width:120px">
                    <input type="number" class="font-weight-medium" style="width:100px" v-model="usdcAmount" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" readonly="true"  @change="calcSct">
                  </div>
                  <div class="font-weight-regular ml-1 grey--text">USDT</div>
                </v-card>
                <div class="text-body-2 text-center font-weight-medium mt-2">{{ $t('Page.Home.Buycon5') }}</div>
              </div>
              <div class="my-4 text-h5 font-weight-regular" style="color:#8A694C">↓</div>
              <div>
                <v-card elevation="0" color="#F1E8E7" class="d-flex justify-center align-center pa-2">
                    <v-img width="32" height="32" src="@/assets/images/logo.png" class="rounded-circle"></v-img>
                  <div class="pa-2 ml-4" style="width:120px">
                    <input autofocus focus  type="number" class="font-weight-medium" style="width:100px" v-model="sctAmount" onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode)))" readonly="true" @change="calcUsdc">
                  </div>
                  <div class="font-weight-regular ml-1 grey--text">SCT</div>
                </v-card>
                <div class="text-body-2 text-center font-weight-medium mt-2">{{ $t('Page.Home.Buycon4') }}</div>
              </div>
                
              <v-btn  depressed href="https://dapp.supercells.pro/swap" target="_blank" width="240" class="mb-6 rounded-pill" min-height="50" color="#8A694C" style="margin-top:50px">
                <div class="text-body-1 font-weight-medium white--text">{{ $t('Page.Home.Buycon6') }}</div>
              </v-btn>
            </div>
          </v-card>
        </div>
          <div class="text-h6 text-center mt-15 mb-6">{{ $t('Page.Home.Title2') }}</div>
          <div width="100%" height="100" class="text-center py-4" style="border: 2px solid #77562e;background: linear-gradient(180deg,#ac8250, #9b7240 100%);"> 
            <div class="white--text">
              <div class="text-caption font-weight-medium px-5 text-left" v-html="$t('Page.Home.PlanD')" style="white-space:break-spaces;line-height:1.1em!important;"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="block-b pb-5 px-6">
        <div class="text-h6 text-center pt-4">{{ $t('Page.Home.SupportDesc1') }}</div>
        <div class="text-caption mt-3 mb-4"  v-html="$t('Page.Home.SupportDescription')"></div>
        <v-img width="100%" src="@/assets/images/block_b_img.png" class="d-block"></v-img>
      </div>

      <div class="block-c pt-10 pb-5 px-6">
        <div class="text-h6 text-center pt-4">{{ $t('Page.Home.PrivTitle') }}</div>
        <div class="text-body-2 mt-3 mb-4">{{ $t('Page.Home.PrivDescription') }}</div>
        <v-img width="100%" src="@/assets/images/block_c_img.png" class="d-block"></v-img>
      </div>

      <div class="bg-block py-15 px-5">
        <div class="text-h6 text-center">{{ $t('AppBar.SubMenu.Partner') }}</div>
        <div class="d-flex justify-space-between align-center px-4">
          <img height="60px" src="@/assets/images/logo-1.png" class="d-block mt-6" />
          <img height="60px" src="@/assets/images/logo-2.png" class="d-block mt-6" />
        </div>
        <div class="d-flex justify-space-between align-center px-4">
          <img height="60px" src="@/assets/images/logo-3.png" class="d-block mt-6" />
          <img height="60px" src="@/assets/images/logo-9.png" class="d-block mt-6" />
          <!-- <img height="60px" src="@/assets/images/logo-4.png" class="d-block mt-6" /> -->
        </div>
        <div class="d-flex justify-space-between align-center px-4">
          <img height="60px" src="@/assets/images/logo-5.png" class="d-block mt-6" />
          <img height="60px" src="@/assets/images/logo-6.png" class="d-block mt-6" />
        </div>
        <div class="d-flex justify-space-between align-center px-4">
          <img height="60px" src="@/assets/images/logo-7.png" class="d-block mt-6" />
          <img height="60px" src="@/assets/images/logo-8.png" class="d-block mt-6" />
        </div>
        <!-- <div class="d-flex justify-space-between align-center px-4">
          <img height="60px" src="@/assets/images/logo-9.png" class="d-block mt-6" />
        </div> -->
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//  import FlipCountdown from 'vue2-flip-countdown'
import Footer from '../components/Footer.vue'


export default {
  name: 'Home',
  components: {
    Footer,
    // FlipCountdown 
  },
  mounted(){
    this.newsil8n = this.$i18n.locale
    // window.scrollY=0
    // window.addEventListener('scroll',()=>{
    //   console.log(window.scrollX,window.scrollY);
    // });
  },
  data: () => ({
    newsil8n:'zh',
    usdcAmount: 2000,
    sctAmount: 200000,
    flipCountdow:true
  }),
  methods: {
    calcUsdc() {
      if (!this.sctAmount) {
        this.usdcAmount = 2000;
        this.sctAmount = 200000;
        return;
      }

      this.sctAmount = parseFloat(this.sctAmount).toFixed(2);
      this.usdcAmount = parseFloat(this.sctAmount * 0.01).toFixed(2);
    },

    calcSct() {
      if (!this.usdcAmount) {
        this.usdcAmount = 2000;
        this.sctAmount = 200000;
        return;
      }

      this.usdcAmount = parseFloat(this.usdcAmount).toFixed(2);
      this.sctAmount = parseFloat(this.usdcAmount / 0.01).toFixed(2);
    },
    timeElapsedHandler(){
      console.log(111111);
      this.flipCountdow = false;
    }
  },
  watch:{
    '$i18n.locale'(newil8n){
    this.newsil8n=newil8n;
    }
  },
}
</script>

<style lang="scss" scoped>
  .f18 {
    font-size: 18px;
  }

  .pc-container {
    min-width: 768px;
  }

  .banner {
    // min-height: 600px;
    position: relative;
    // background-image: url("../assets/images/banner2.jpg");
    // background-size: 100%;
    // background-size: 2592px 1230px;
    // background-repeat: no-repeat;
    // background-position: center center;
    .text-box{
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      left: 0;
      top: 0;
    }

    .text-title {
      font-size: 64px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
    }
  }
  .banner2 {
    position: relative;
    padding:30px;
    background-image: url("../assets/images/bg-tsl.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;

    .text-title {
      font-size: 38px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
    }
    .text-title2 {
      font-size: 28px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 400;
    }
  }

  .block-a {
    position: relative;
    background: #FCF9F6;
    z-index: 1;
  }

  .block-a::before, .block-a::after,
  .block-b::before, .block-b::after,
  .block-c::before, .block-c::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 0;
  }

  .block-a::before, .block-a::after {
    z-index: -1;
  }

  .block-a::before {
    width: 327px;
    height: 387.93px;
    left: -125px;
    top: 0;
    background: #FFEAD0;
    filter: blur(400px);
  }

  .block-a::after {
    width: 266px;
    height: 315.57px;
    right: -50px;
    bottom: 0;
    background: rgba(255, 233, 206, 0.87);
    filter: blur(400px);
  }

  .block-b {
    position: relative;
  }

  .block-b::before {
    width: 400px;
    height: 400px;
    right: -50px;
    top: -50px;
    background: rgba(255, 239, 198, 0.38);
    filter: blur(400px);
  }

  .block-b::after {
    width: 455px;
    height: 455px;
    left: -50px;
    bottom: -80px;
    background: rgba(210, 233, 255, 0.26);
    filter: blur(400px);
  }

  .block-c {
    position: relative;
    background: #FAFCF6;
  }

  .block-c::before {
    width: 443px;
    height: 443px;
    left: -50px;
    top: 0;
    background: rgba(246, 255, 228, 0.88);
    filter: blur(400px);
  }

  .block-c::after {
    width: 443px;
    height: 443px;
    right: -20px;
    bottom: -80px;
    background: rgba(245, 255, 225, 0.81);
    filter: blur(400px);
  }

  .link-text {
    color:#A09079!important;
    text-decoration: underline;
  }

  input {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
     -webkit-appearance: none;
  }
  
  input[type="number"] {
     -moz-appearance: textfield;
  }


  @media screen and (max-width: 960px) {
    .mobile-container {
      overflow: hidden;
    }
    
    .block-a::before,
    .block-b::before,
    .block-c::before {
      width: 40%;
      height: 30%;
      left: -10%;
      top: -10%;
      background: #FFEAD0;
      filter: blur(400px);
    }

    .block-a::after,
    .block-b::after,
    .block-c::after {
      width: 40%;
      height: 30%;
      right: -10%;
      bottom: -10%;
      background: rgba(255, 233, 206, 0.87);
      filter: blur(400px);
    }

    div {
      position: relative;
      z-index: 1;
    }
    .banner {
      position: relative;
      min-height: 400px;
      background: url("../assets/images/banner2.jpg");
      background-size: 864px 410px;
      background-position: center center;

      .text-title {
        font-size: 30px;
        margin-top: 20px;
      }
      .text-title2 {
        font-size: 24px;
      }
    }
    .banner2 {
      .text-title {
        font-size: 26px;
      }
      .text-title2 {
        font-size: 20px;
      }
    }
  }
</style>
